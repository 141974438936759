import React from "react"

const iconThree = () => {
  return (
    <svg
      version="1.1"
      id="Layer_4"
      x="0px"
      y="0px"
      viewBox="0 0 170.1 170.1"
      className="home-icon"
    >
      <g>
        <circle className="st000" cx="85" cy="85" r="79.5" />
        <g>
          <g>
            <rect
              x="35.7"
              y="45.3"
              className="st111"
              width="98.5"
              height="79.5"
            />
            <rect
              x="35.7"
              y="45.3"
              className="st111"
              width="98.5"
              height="9.5"
            />
            <line className="st111" x1="39.3" y1="49.9" x2="42.3" y2="49.9" />
            <line className="st111" x1="44.6" y1="49.9" x2="47.7" y2="49.9" />
            <line className="st111" x1="49.9" y1="49.9" x2="53" y2="49.9" />
          </g>
          <g>
            <g>
              <g>
                <line
                  className="st222"
                  x1="41.7"
                  y1="109.9"
                  x2="67.3"
                  y2="109.9"
                />
                <line
                  className="st222"
                  x1="41.7"
                  y1="116.4"
                  x2="67.3"
                  y2="116.4"
                />
              </g>
              <rect
                id="box-1"
                x="42.6"
                y="94.3"
                className="st111"
                width="23.7"
                height="9.5"
              />
            </g>
            <g>
              <g>
                <line
                  className="st222"
                  x1="72.2"
                  y1="109.9"
                  x2="97.8"
                  y2="109.9"
                />
                <line
                  className="st222"
                  x1="72.2"
                  y1="116.4"
                  x2="97.8"
                  y2="116.4"
                />
              </g>
              <rect
                id="box-2"
                x="73.1"
                y="94.3"
                className="st111"
                width="23.7"
                height="9.5"
              />
            </g>
            <g>
              <g>
                <line
                  className="st222"
                  x1="102.7"
                  y1="109.9"
                  x2="128.3"
                  y2="109.9"
                />
                <line
                  className="st222"
                  x1="102.7"
                  y1="116.4"
                  x2="128.3"
                  y2="116.4"
                />
              </g>
              <rect
                id="box-3"
                x="103.6"
                y="94.3"
                className="st111"
                width="23.7"
                height="9.5"
              />
            </g>
          </g>
          <rect
            x="35.7"
            y="68.3"
            className="st111"
            width="98.5"
            height="18.9"
          />
          <g>
            <line className="st222" x1="41.7" y1="61.9" x2="67.3" y2="61.9" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default iconThree
