import React from "react"

const iconOne = () => {
  return (
    <svg
      className="home-icon"
      version="1.1"
      id="Layer_4"
      x="0px"
      y="0px"
      viewBox="0 0 170.1 170.1"
    >
      <g>
        <g>
          <polyline
            className="st0"
            id="card"
            points="113.8,82.2 113.8,56.1 95,37.3 42.7,37.3 42.7,56.7 42.7,82.2 42.7,134.4 113.8,134.4 113.8,117.2 
					"
          />
          <polygon className="st1" points="95,56.1 113.8,56.1 95,37.3 		" />
          <g>
            <line className="st1" x1="49" y1="57.7" x2="69.3" y2="57.7" />
            <line className="st1" x1="49" y1="62.4" x2="77.9" y2="62.4" />
            <line className="st1" x1="49" y1="67.1" x2="71.9" y2="67.1" />
          </g>
          <g>
            <line className="st0" x1="123.4" y1="117.4" x2="84.4" y2="117.4" />
            <polyline
              className="st0"
              points="139.1,81.8 139.1,117.4 129.9,117.4 			"
            />
            <line className="st0" x1="57.9" y1="105.9" x2="57.9" y2="81.8" />
            <path
              className="st1"
              d="M122.9,81.8H58c-0.3,1.2,0.3,2.4,1.7,3.3l34.6,22c2.4,1.5,6.2,1.5,8.5,0L123,94.2"
            />
            <path
              className="st1"
              d="M130.1,89.8l7.3-4.6c1.1-0.7,1.7-1.6,1.8-2.6v-0.3c0-0.2,0-0.3-0.1-0.5h-8.9"
            />
          </g>
          <rect x="49.5" y="106" className="st0" width="34.6" height="21.6" />
          <path
            className="st0"
            d="M58.7,49c0,2.2-1.8,4-4,4s-4-1.8-4-4s1.8-4,4-4C56.9,45,58.7,46.8,58.7,49z"
          />
          <g>
            <line className="st1" x1="53.9" y1="112.7" x2="64.9" y2="112.7" />
            <line className="st1" x1="53.9" y1="117.4" x2="69.5" y2="117.4" />
            <line className="st1" x1="53.9" y1="122.1" x2="66.2" y2="122.1" />
          </g>
          <g id="pen">
            <g>
              <path
                className="st2"
                d="M123.5,52.8v4.4h6.6v-4.4c0-0.3-0.3-0.5-0.7-0.5h-5.1C123.8,52.3,123.5,52.5,123.5,52.8z"
              />
              <rect
                x="123.5"
                y="57.2"
                className="st2"
                width="6.6"
                height="66.5"
              />
            </g>
            <path
              className="st2"
              d="M126.8,123.1c-1.2,0-2.3,0.2-3.3,0.5l3.3,8.5l3.3-8.5C129.1,123.3,127.9,123.1,126.8,123.1z"
            />
            <g>
              <rect
                x="123.5"
                y="57.1"
                className="st2"
                width="6.6"
                height="5.6"
              />
            </g>
            <path
              className="st3"
              d="M127.8,129.3c-0.3,0-0.7,0-1,0c-0.4,0-0.8,0-1.1,0.1l1.1,2.8L127.8,129.3z"
            />
          </g>
        </g>
        <circle className="st4" cx="85" cy="85" r="79.5" />
      </g>
    </svg>
  )
}

export default iconOne
