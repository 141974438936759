import React from "react"

const iconTwo = () => {
  return (
    <svg
      className="home-icon"
      version="1.1"
      id="Layer_4"
      x="0px"
      y="0px"
      viewBox="0 0 170.1 170.1"
    >
      <g>
        <circle className="st00" cx="85" cy="85" r="79.5" />
        <g>
          <rect x="39.7" y="54.5" className="st11" width="90.5" height="51.2" />
          <rect x="37.4" y="46.3" className="st11" width="95.2" height="8.3" />
          <rect x="37.4" y="106.1" className="st11" width="95.2" height="5.1" />
          <line className="st11" x1="85" y1="111.2" x2="85" y2="123.8" />
          <g>
            <line className="st11" x1="71.5" y1="111.2" x2="61.5" y2="123.8" />
            <line className="st11" x1="98.5" y1="111.2" x2="108.5" y2="123.8" />
          </g>
          <g>
            <rect
              id="bar-1"
              x="47.6"
              y="86.9"
              className="st11"
              width="6.6"
              height="11.1"
            />
            <rect
              x="54.5"
              y="75.7"
              className="st11"
              width="6.6"
              height="22.3"
            />
            <rect
              x="61.5"
              y="81.9"
              className="st11"
              width="6.6"
              height="16.1"
            />
          </g>
          <g>
            <rect x="74.6" y="91.6" className="st11" width="6.6" height="6.4" />
            <rect
              id="bar-2"
              x="81.5"
              y="84.2"
              className="st11"
              width="6.6"
              height="13.8"
            />
            <rect
              id="bar-3"
              x="88.5"
              y="75.9"
              className="st11"
              width="6.6"
              height="22.1"
            />
          </g>
          <g>
            <rect
              id="bar-4"
              x="101.6"
              y="83.6"
              className="st11"
              width="6.6"
              height="14.4"
            />
            <rect
              x="108.5"
              y="90.7"
              className="st11"
              width="6.6"
              height="7.3"
            />
            <rect
              id="bar-5"
              x="115.5"
              y="73.8"
              className="st11"
              width="6.6"
              height="24.2"
            />
          </g>
          <line className="st11" x1="47" y1="63.4" x2="70.3" y2="63.4" />
          <line className="st11" x1="47" y1="69" x2="56.3" y2="69" />
        </g>
      </g>
    </svg>
  )
}

export default iconTwo
